import { SVG_ENUM } from "enums";
import Button from "shared/Button/Button";
import SVG from "shared/SVG";
import "./EmptyState.scss";

interface EmptyStateProps {
  svg: SVG_ENUM;
  text: string | JSX.Element;
  buttonLabel?: string;
  className?: string;
  onButtonClick?: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  svg,
  text,
  buttonLabel,
  onButtonClick,
  className = "",
}: EmptyStateProps) => {
  return (
    <div className={`empty-state ${className}`}>
      <div className="empty-state__svg">
        <SVG type={svg} />
      </div>
      <div className="empty-state__text">{text}</div>
      {buttonLabel ? (
        <Button
          label={buttonLabel}
          onClick={() => onButtonClick && onButtonClick()}
          size="small"
          styleType="strokeBlack"
        />
      ) : null}
    </div>
  );
};

export default EmptyState;
