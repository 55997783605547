import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { CompanyOptionsType } from "types/CompanyTypes";

interface GetMyCompaniesHookType {
  enabled?: boolean;
}

export default function useGetCompaniesOptions({
  enabled,
}: GetMyCompaniesHookType) {
  return useQuery({
    queryKey: ["companies/options"],
    queryFn: async () => {
      return axiosInstance.get(`/companies/options`).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: CompanyOptionsType[]) => data,
    retry: false,
    enabled,
    staleTime: 1000 * 60 * 5,
  });
}
