import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { UnviewedPostCountType } from "types/PostTypes";

export default function useGetUnviewedPostsCount({
  companyIds,
  enabled,
}: {
  enabled?: boolean;
  companyIds?: number[];
}) {
  return useQuery({
    queryKey: ["posts", companyIds],
    queryFn: async () => {
      return axiosInstance
        .get(
          `posts/unviewed-count${
            companyIds ? `?companyIds=[${companyIds}]` : ""
          }`
        )
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: UnviewedPostCountType) => data,
    retry: false,
    enabled,
    staleTime: 0,
  });
}
