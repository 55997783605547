import "./PostStatusBadge.scss";
import { PostStatusType } from "types/PostTypes";

const PostStatusBadge = ({ status }: { status: PostStatusType }) => {
  const statusToText = {
    draft: "Szkic",
    published: "Opublikowana",
    withdrawn: "Wycofana",
  };

  const lowerCaseStatus = status.toLowerCase() as keyof typeof statusToText;

  return (
    <span className={`post-status post-status--${status}`}>
      <p className="post-status__text">{statusToText[lowerCaseStatus]}</p>
    </span>
  );
};

export default PostStatusBadge;
