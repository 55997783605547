import { SVG_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useGetCompaniesOptions } from "hooks/companies";
import { useGetPostCategories, useStorePost, useUpdatePost } from "hooks/posts";
import {
  Button,
  CalendarInput,
  DropFileInput,
  EmptyState,
  FullscreenModal,
  Input,
  SelectComponent,
  TextEditor,
  TimePicker,
} from "shared";
import { PostType, StorePostType } from "types/PostTypes";
import { postStatusOptions } from "utils";
import { validation } from "./validation";
import EditorJS from "@editorjs/editorjs";
import moment from "moment";
import { useRef } from "react";

interface AddMarketInfoProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  post?: PostType | null;
}

const AddMarketInfo: React.FC<AddMarketInfoProps> = ({
  isOpen,
  onClose,
  onSuccess,
  post,
}) => {
  const editorRef = useRef<EditorJS | null>(null);
  const formRef = useRef<any>(null);
  const { mutateAsync: createPost } = useStorePost();
  const { mutateAsync: updatePost } = useUpdatePost();
  const { data: companiesOptions } = useGetCompaniesOptions({
    enabled: isOpen,
  });
  const { data: categoryOptions } = useGetPostCategories();

  const companyIds = post?.companies?.map((company) => company.id) || [];
  const categoryIds = post?.categories?.map((category) => category.id) || [];
  const initialValues = {
    companyIds: companyIds || [],
    categoryIds: categoryIds || [],
    title: post?.title || "",
    link: post?.link || "",
    source: post?.source || "",
    status: post?.status || "draft",
    publishedAt: post?.publishedAt || moment().format("YYYY-MM-DD HH:mm"),
    content: post?.content || "",
    file: undefined,
    id: post?.id || null,
    time: post?.publishedAt
      ? moment(post?.publishedAt).format("HH:mm")
      : moment().format("HH:mm"),
  } as StorePostType;

  const onSubmit = async (values: typeof initialValues) => {
    const combinedPublishDate = `${moment(values.publishedAt).format(
      "YYYY-MM-DD"
    )} ${values.time}`;
    !!post
      ? await updatePost(
          {
            ...post,
            ...values,
            file: values.file || undefined,
            publishedAt: combinedPublishDate,
          },
          {
            onSuccess: () => {
              onSuccess && onSuccess();

              onClose();
            },
          }
        )
      : await createPost(
          { ...values, publishedAt: combinedPublishDate },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
          }
        );
    onClose();
  };

  const handleOutsideSubmit = (handleSubmit: () => void) => {
    editorRef.current?.save().then(async (outputData) => {
      await formRef.current?.setFieldValue(
        "content",
        JSON.stringify(outputData)
      );
      handleSubmit();
    });
  };

  return (
    <FullscreenModal
      isOpen={isOpen}
      onClose={onClose}
      className="fullscreen-modal--610"
    >
      <h2 className="fullscreen-modal__header">
        {!!post ? "Edytuj informację rynkową" : "Dodaj informację rynkową"}
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
        innerRef={formRef}
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100">
            <Field
              label="Spółki"
              name="companyIds"
              id="companyIds"
              as={SelectComponent}
              options={companiesOptions}
              isMulti
              isSearchable
              errors={errors}
            />

            <Field
              type="text"
              id="title"
              name="title"
              label={"Tytuł wpisu"}
              as={Input}
              maxLength={200}
            />
            <div className="fullscreen-modal__input-group">
              <Field
                type="text"
                id="link"
                name="link"
                label={"Link"}
                as={Input}
                maxLength={50}
              />
              <Field
                type="text"
                id="source"
                name="source"
                label={"Nazwa odnośnika"}
                as={Input}
                maxLength={50}
              />
            </div>
            <Field
              label="Kategoria"
              name="categoryIds"
              id="categoryIds"
              as={SelectComponent}
              options={categoryOptions}
              isMulti
              isSearchable
              errors={errors}
            />

            <Field
              label="Status"
              name="status"
              id="status"
              as={SelectComponent}
              options={postStatusOptions}
              errors={errors}
            />
            <div className="fullscreen-modal__input-group">
              <Field
                type="date"
                id="publishedAt"
                name="publishedAt"
                label={"Data publikacji"}
                component={CalendarInput}
                errors={errors}
              />
              <Field
                type="time"
                id="time"
                name="time"
                component={TimePicker}
                errors={errors}
              />
            </div>
            <Field
              type="text"
              id="content"
              name="content"
              label={"Treść wpisu"}
              component={TextEditor}
              errors={errors}
              editorRef={editorRef}
            />
            <Field
              label={"Dodaj zdjęcie"}
              name="file"
              required
              id="file"
              as={DropFileInput}
              accept="image/*"
              errors={errors}
            />
            {!values.file && post?.file && (
              <p className="drop-file-input__file">{post?.file.name}</p>
            )}
            <EmptyState
              svg={SVG_ENUM.INFO}
              className="empty-state--transparent empty-state--modal"
              text={
                "Przed opublikowaniem tej informacji, upewnij się, że wszystkie wprowadzone dane są poprawne. Po opublikowaniu informacji, wszyscy klienci otrzymają  notyfikację."
              }
            />
            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                size="large"
                styleType="strokeBlack"
                className="button--wide"
              />
              <Button
                label={!!post ? "Zapisz" : "Dodaj"}
                onClick={() => handleOutsideSubmit(handleSubmit)}
                size="large"
                styleType="primary"
                className="button--wide"
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default AddMarketInfo;
