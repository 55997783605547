import { PACKET_ENUM, SVG_ENUM } from "enums";
import { useGetMe } from "hooks/auth";
import { PostTypeType } from "screens/marketInfoPage/MarketInfoPage";
import { SVG } from "shared";
import "./PostTypeSwitch.scss";
import { PacketModal } from "features/users";
import { useState } from "react";

interface Props {
  type: PostTypeType;
  setType: React.Dispatch<React.SetStateAction<PostTypeType>>;
}

const PostTypeSwitch = ({ type, setType }: Props) => {
  const { data: authorizedUser } = useGetMe();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isRelianceLocked =
    !authorizedUser?.package || authorizedUser?.package === PACKET_ENUM.BASIC;
  const isOtherLocked =
    !authorizedUser?.package ||
    authorizedUser?.package === PACKET_ENUM.BASIC ||
    authorizedUser?.package === PACKET_ENUM.STANDARD;

  const handleTypeChange = (type: PostTypeType) => {
    setType(type);
    sessionStorage.removeItem("selectedPostsCompanyOrigin");
  };

  return (
    <div className="post-type-switch__wrapper">
      <PacketModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        parent="homepage"
      />
      <div className="post-type-switch">
        <span
          className={`post-type-switch__item ${
            type === "my" ? "post-type-switch__item--active" : ""
          }`}
          onClick={() => handleTypeChange("my")}
        >
          Moje spółki
          <SVG type={SVG_ENUM.CIRCLE_CHECK} />
        </span>
        <span
          className={`post-type-switch__item 
                ${type === "reliance" ? "post-type-switch__item--active" : ""}
                ${isRelianceLocked ? "post-type-switch__item--locked" : ""}
                `}
          onClick={() => !isRelianceLocked && handleTypeChange("reliance")}
        >
          Spółki Reliance
          <SVG
            type={isRelianceLocked ? SVG_ENUM.LOCK : SVG_ENUM.CIRCLE_CHECK}
          />
        </span>
        <span
          className={`post-type-switch__item 
            ${type === "other" ? "post-type-switch__item--active" : ""}
            ${isOtherLocked ? "post-type-switch__item--locked" : ""}
            `}
          onClick={() => !isOtherLocked && handleTypeChange("other")}
        >
          Spółki innych firm
          <SVG type={isOtherLocked ? SVG_ENUM.LOCK : SVG_ENUM.CIRCLE_CHECK} />
        </span>
      </div>
      {isRelianceLocked || isOtherLocked ? (
        <p className="post-type-switch__upper-package">
          <b onClick={() => setIsModalOpen(true)}>Wykup wyższy pakiet</b>, aby
          odblokować funkcję.
        </p>
      ) : null}
    </div>
  );
};

export default PostTypeSwitch;
