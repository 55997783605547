/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Button, DashboardTopBar, DashboardTopPortal } from "shared";

import { useNavigate } from "react-router-dom";
import { useGetMe } from "hooks/auth";
import { ROLES_ENUM, ROUTE_ENUM } from "enums";

import { AddMarketInfo } from "features/marketInfo";
import { PostType } from "types/PostTypes";
import { PostsGrid } from "./components";
import DeleteMarketInfoModal from "features/marketInfo/DeleteMarketInfoModal/DeleteMarketInfoModal";

export type PostTabsType = "all" | "draft" | "withdrawn" | "user";
export type PostTypeType = "my" | "reliance" | "other";

export interface PostModalManage {
  type: string;
  post: PostType | null;
}

const MarketInfo = () => {
  const [modalManage, setModalManage] = useState<PostModalManage>({
    type: "",
    post: null,
  });
  const navigate = useNavigate();
  const { data: authorizedUser } = useGetMe();
  const [tab, setTab] = useState<PostTabsType>(
    authorizedUser?.role === ROLES_ENUM.USER ? "user" : "all"
  );
  const isAdmin = authorizedUser?.role === ROLES_ENUM.ADMIN;

  const handleChangeTab = (item: PostTabsType) => {
    setTab(item);
  };

  const cleanModalManage = () => {
    setModalManage({ type: "", post: null });
    navigate(ROUTE_ENUM.MARKET_INFORMATION);
  };

  return (
    <>
      <AddMarketInfo
        isOpen={modalManage.type === "addPost"}
        onClose={() => cleanModalManage()}
        post={modalManage.post}
      />

      <DeleteMarketInfoModal
        isOpen={modalManage.type === "deletePost"}
        onClose={() => cleanModalManage()}
        post={modalManage.post}
      />

      <div className="companies">
        <DashboardTopBar>
          <h1 className="dashboard__header">Informacje rynkowe</h1>
          <div className="companies__buttons">
            {isAdmin ? (
              <>
                <Button
                  className="dashboard__top-button button--rounded dashboard__fixed-button"
                  onClick={() =>
                    setModalManage({ type: "addPost", post: null })
                  }
                  label="Dodaj informację +"
                />
              </>
            ) : null}
          </div>
        </DashboardTopBar>
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content">
            <DashboardTopPortal>
              <div className="dashboard__tabs">
                {authorizedUser?.role === ROLES_ENUM.ADMIN ? (
                  <>
                    <span
                      onClick={() => handleChangeTab("all")}
                      className={`dashboard__tab ${
                        tab === "all" ? "dashboard__tab--active" : ""
                      } `}
                    >
                      Wszystkie informacje
                    </span>
                    <span
                      onClick={() => handleChangeTab("draft")}
                      className={`dashboard__tab ${
                        tab === "draft" ? "dashboard__tab--active" : ""
                      } `}
                    >
                      Szkice
                    </span>
                    <span
                      onClick={() => handleChangeTab("withdrawn")}
                      className={`dashboard__tab ${
                        tab === "withdrawn" ? "dashboard__tab--active" : ""
                      } `}
                    >
                      Wycofane
                    </span>
                  </>
                ) : null}
              </div>
            </DashboardTopPortal>
            <PostsGrid tab={tab} setModalManage={setModalManage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketInfo;
