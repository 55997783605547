import { Field, Form, Formik } from "formik";
import { useGetCompaniesOptions } from "hooks/companies";
import { useGetPosts } from "hooks/posts";
import { useState } from "react";
import {
  PostModalManage,
  PostTabsType,
  PostTypeType,
} from "screens/marketInfoPage/MarketInfoPage";
import { Pagination, SelectComponent } from "shared";
import "./PostsGrid.scss";
import PostItem from "../PostItem/PostItem";
import PostTypeSwitch from "../PostTypeSwitch/PostTypeSwitch";
import PostCategories from "../PostCategories/PostCategories";
import { isDesktop } from "utils";

interface PostsGridProps {
  tab: PostTabsType;
  setModalManage: React.Dispatch<React.SetStateAction<PostModalManage>>;
}

const PostsGrid = ({ tab, setModalManage }: PostsGridProps) => {
  const selectedPostsCompany = sessionStorage.getItem("selectedPostsCompany");
  const selectedPostsCompanyOrigin: any = sessionStorage.getItem(
    "selectedPostsCompanyOrigin"
  );
  const [page, setPage] = useState(1);
  const [companyId, setCompanyId] = useState(
    selectedPostsCompany ? parseInt(selectedPostsCompany) : null
  );
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [type, setType] = useState<PostTypeType>(
    selectedPostsCompanyOrigin
      ? selectedPostsCompanyOrigin === "reliance"
        ? "my"
        : selectedPostsCompanyOrigin
      : "my"
  );

  const isAdmin = tab !== "user";
  const { data } = useGetPosts({
    page,
    isAdmin,
    status: tab === "draft" || tab === "withdrawn" ? tab : undefined,
    companyId: companyId ? companyId : undefined,
    type: isAdmin ? undefined : type,
    categoryIds: selectedCategories.length ? selectedCategories : undefined,
  });

  const { data: companiesOptions } = useGetCompaniesOptions({});

  const handleChangePage = (e: number) => {
    setPage(e);
  };

  return (
    <div className={"posts-grid"}>
      <div
        className={`posts-grid__top ${isAdmin ? "posts-grid__top--admin" : ""}`}
      >
        <Formik
          initialValues={{
            companyId,
          }}
          onSubmit={(values: any) => console.log(values)}
          enableReinitialize
        >
          {() => (
            <Form className="posts-grid__search">
              <Field
                label="Znajdź spółkę"
                name="companyId"
                id="companyId"
                placeholder="Szukaj"
                as={SelectComponent}
                onSelectChange={(e: any) => {
                  setCompanyId(e?.value);
                  sessionStorage.removeItem("selectedPostsCompany");
                }}
                options={companiesOptions}
                isClearable
              />
            </Form>
          )}
        </Formik>
        {!isAdmin ? <PostTypeSwitch type={type} setType={setType} /> : null}
      </div>
      {!isAdmin && (
        <>
          {isDesktop() && <h2 className="select__label">Kategoria</h2>}
          <PostCategories
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </>
      )}
      <div
        className={`posts-grid__wrapper ${
          isAdmin ? "" : "posts-grid__wrapper--user"
        }`}
      >
        {data?.data.map((post) => (
          <PostItem data={post} setModalManage={setModalManage} />
        ))}
      </div>

      {data?.meta && data?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={data?.meta?.lastPage}
          currentPage={data?.meta?.currentPage || page}
          onPageChange={(e: any) => handleChangePage(e)}
        />
      )}
    </div>
  );
};

export default PostsGrid;
