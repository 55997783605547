import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { OptionsType } from "types/UtilsTypes";

export default function useGetPostCategories() {
  return useQuery({
    queryKey: ["post-categories"],

    queryFn: async () => {
      return axiosInstance.get(`post-categories`).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: OptionsType[]) => data,
    staleTime: 1000 * 60 * 60 * 24,
  });
}
