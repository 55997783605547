import { SVG_ENUM } from "enums";
import { useGetPostCategories } from "hooks/posts";
import { Button } from "shared";
import { isDesktop } from "utils";
import CategoriesModal from "../CategoriesModal/CategoriesModal";
import { useState } from "react";

interface Props {
  selectedCategories: number[];
  setSelectedCategories: (value: number[]) => void;
}

const PostCategories = ({
  setSelectedCategories,
  selectedCategories,
}: Props) => {
  const { data: categoryOptions } = useGetPostCategories();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCategoryClick = (id: number) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(selectedCategories.filter((item) => item !== id));
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };
  return (
    <div className="posts-grid__categories">
      {isDesktop() ? (
        categoryOptions?.map((category) => (
          <span
            className={`posts-grid__category ${
              selectedCategories.includes(category.value)
                ? "posts-grid__category--active"
                : ""
            }`}
            onClick={() => handleCategoryClick(category.value)}
          >
            {category.label}
          </span>
        ))
      ) : (
        <>
          <CategoriesModal
            handleCategoryClick={handleCategoryClick}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
          <Button
            className="button--wide"
            styleType="primary"
            onClick={() => setIsModalOpen(true)}
            size="medium"
            label="Filtry"
            svg={SVG_ENUM.FILTER}
          />
        </>
      )}
    </div>
  );
};

export default PostCategories;
