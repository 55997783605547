import { Field, Formik, Form } from "formik";
import { useStoreAnalyze } from "hooks/analyzes";
import moment from "moment";
import { Button, CalendarInput, FullscreenModal, Input } from "shared";
import { StoreAnalyzeType } from "types/AnalyzeTypes";

import validation from "./validation";

interface AddAnalyzeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const AddAnalyzeModal: React.FC<AddAnalyzeModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const { mutateAsync: storeCompany } = useStoreAnalyze();
  const today = new Date();
  const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

  const initialValues = {
    companyName: "",
    status: "pending",
    nip: "",
    deadlineAt: "",
  };

  const onSubmit = async (values: StoreAnalyzeType) => {
    await storeCompany(
      {
        ...values,
        deadlineAt: moment(values.deadlineAt).format("YYYY-MM-DD HH:mm"),
      },
      {
        onSuccess: () => {
          onSuccess && onSuccess();
          onClose();
        },
      }
    );
    onClose();
  };

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">Zleć analizę spółki</h2>
      <p className="fullscreen-modal__subtitle">
        Podaj nazwę i numer identyfikacji podatkowej spółki, którą chcesz
        przeanalizować.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100 fullscreen-modal__select-margin">
            <Field
              type="text"
              id="companyName"
              name="companyName"
              label={"Nazwa spółki"}
              as={Input}
              maxLength={100}
            />
            <Field
              type="text"
              id="nip"
              name="nip"
              label={"Numer identyfikacji podatkowej"}
              as={Input}
              maxLength={11}
            />

            <Field
              type="date"
              id="deadlineAt"
              name="deadlineAt"
              label={"Preferowany termin wykonania analizy"}
              component={CalendarInput}
              minDate={nextWeek}
              errors={errors}
            />
            <p className="fullscreen-modal__text">
              Na wykonanie analizy rynkowej spółki potrzebujemy minimum 7 dni.
            </p>

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={"Potwierdź"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default AddAnalyzeModal;
