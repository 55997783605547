import { useDeletePost } from "hooks/posts";
import { Button, PopupModal } from "shared";
import { PostType } from "types/PostTypes";

interface DeleteMarketInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: PostType | null;
  onSuccess?: () => void;
}

const DeleteMarketInfoModal: React.FC<DeleteMarketInfoModalProps> = ({
  isOpen,
  onClose,
  post,
  onSuccess,
}) => {
  const { mutate: deletePost } = useDeletePost();

  const handleDelete = async () => {
    if (post) {
      await deletePost(post?.id, {
        onSuccess: () => {
          onSuccess && onSuccess();
          onClose();
        },
      });
      onClose();
    }
  };
  return (
    <PopupModal isOpen={isOpen} onClose={onClose}>
      <h2 className="popup-modal__header popup-modal__header--mb-20">Usuń informację rynkową</h2>
      <p className="popup-modal__subtitle">
        Czy jesteś pewien, że chcesz usunąć informację o tytule
        <span className="fw-600"> {post?.title}</span>?
      </p>
      <div className="side-modal__buttons">
        <Button
          label="Anuluj"
          onClick={onClose}
          size="large"
          styleType="strokeBlack"
          className="button--wide"
        />
        <Button
          label="Potwierdź"
          onClick={() => handleDelete()}
          styleType="primary"
          size="large"
          className="button--wide"
        />
      </div>
    </PopupModal>
  );
};

export default DeleteMarketInfoModal;
