import { ROUTE_ENUM, SVG_ENUM } from "enums";
import { useNavigate } from "react-router";
import { SVG } from "shared";
import { CompanyOriginType } from "types/CompanyTypes";

export interface MarketInfoSpeakerProps {
  id: number;
  postsCount: number;
  origin: CompanyOriginType["origin"];
}

export default function MarketInfoSpeaker(props: MarketInfoSpeakerProps) {
  const navigate = useNavigate();
  const { id, postsCount = 0, origin } = props;
  const setSelectedPostsCompany = (id: number) => {
    sessionStorage.setItem("selectedPostsCompany", id.toString());
    sessionStorage.setItem("selectedPostsCompanyOrigin", origin);
    navigate(ROUTE_ENUM.MARKET_INFORMATION);
  };

  const isNewPosts = postsCount > 0;
  return (
    <div
      className={`companies-table__announcement ${
        isNewPosts ? "companies-table__announcement--new" : ""
      }`}
      onClick={() => setSelectedPostsCompany(id)}
    >
      <SVG type={SVG_ENUM.ANNOUCEMENT} />
      <span>{postsCount}</span>
    </div>
  );
}
