/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import "./TextEditor.scss";
import { FieldProps } from "formik";
import TextEditorTranslation from "./TextEditorTranslation";

interface TextEditorProps {
  label: string;
  name: string;
  placeholder?: string;
  className?: string;
  tools?: any;
  data?: OutputData;
  onChange?: (data: OutputData) => void;
  setSaveFunction?: (saveFn: () => Promise<OutputData>) => void;
  editorRef: React.MutableRefObject<EditorJS | null>;

  // formik
  field: any;
  errors: any;
  form: any;
  id?: string;
}

function TextEditor({
  label,
  className = "",
  name,
  placeholder = "Type here...",
  tools,
  data,
  onChange,
  field,
  form,
  errors,
  editorRef,
  id,
}: TextEditorProps & FieldProps) {
  const uniqueHolderId = id || name;

  useEffect(() => {
    const editor = new EditorJS({
      holder: uniqueHolderId,
      
      i18n: { messages: TextEditorTranslation },
      tools: tools || {
        header: Header,
        list: List,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          preserveBlank: true,
        },
      },
      data: { blocks: [] },
      placeholder: placeholder,
    });

    editorRef.current = editor;

    return () => {
      if (editorRef.current) {
        editorRef.current.isReady
          .then(() => {
            editorRef.current?.destroy?.();
          })
          .catch((err) => console.error("Error during EditorJS cleanup", err))
          .finally(() => {
            editorRef.current = null;
          });
      }
    };
  }, [uniqueHolderId, tools, data, placeholder]);

  const updateField = async () => {
    if (editorRef.current && field.value) {
      await editorRef.current.isReady.then(() => {
        if (editorRef?.current) {
          editorRef?.current.render(JSON.parse(field.value));
        }
      });
    }
  };

  useEffect(() => {
    updateField();
  }, [field.value]);

  return (
    <div
      className={`texteditor__wrapper ${className} ${
        form.touched[field.name] && errors[field.name]
          ? "texteditor__wrapper--error"
          : ""
      }`}
    >
      <label className="texteditor__label" htmlFor={field.name}>
        {label}
      </label>
      <div className="texteditor texteditor__fonts" id={uniqueHolderId}></div>

      {errors && form.touched && (
        <div className="texteditor__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )}
    </div>
  );
}

export default TextEditor;
