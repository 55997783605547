import { Field, Form, Formik } from "formik";
import { useUpdateAnalyze } from "hooks/analyzes";
import moment from "moment";
import React from "react";
import {
  Button,
  CalendarInput,
  DropFileInput,
  SelectComponent,
  SVG,
  Textarea,
} from "shared";
import { AnalyzeType, UpdateAnalyzeType } from "types/AnalyzeTypes";
import { analyzeStatusOptions } from "utils";
import validation from "./validation";
import { SVG_ENUM } from "enums";

interface AnalyzeFormProps {
  analyze: AnalyzeType | null;
  onClose: () => void;
}

const AnalyzeForm: React.FC<AnalyzeFormProps> = ({ analyze, onClose }) => {
  const today = new Date();
  const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  const { mutateAsync: updateAnalyze } = useUpdateAnalyze();

  const initialValues = {
    deadlineAt: analyze?.deadlineAt || "",
    status: analyze?.status || "pending",
    file: analyze?.file as File | undefined,
    explanation: analyze?.explanation || "",
    uuid: analyze?.uuid,
  };

  const onSubmit = async (values: UpdateAnalyzeType) => {
    updateAnalyze(values, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues as UpdateAnalyzeType}
      onSubmit={(values: UpdateAnalyzeType) =>
        onSubmit({
          ...values,
          deadlineAt: moment(values.deadlineAt).format("YYYY-MM-DD HH:mm"),
        })
      }
      validationSchema={validation()}
      enableReinitialize
    >
      {({ errors, values, handleSubmit, touched }) => {
        const isFileInputVisible =
          values.status === "completed" || analyze?.file || values.file;

        const isExplanationVisible = values.status === "canceled";

        return (
          <Form className="m-width-100 fullscreen-modal__select-margin analyze-info-modal__form">
            <Field
              type="date"
              id="deadlineAt"
              name="deadlineAt"
              label={"Termin wykonania"}
              component={CalendarInput}
              minDate={nextWeek}
              errors={errors}
            />
            <Field
              label="Status"
              name="status"
              id="status"
              as={SelectComponent}
              options={analyzeStatusOptions}
            />
            {isFileInputVisible ? (
              <Field
                label={"Załącz gotową analizę"}
                name="file"
                required
                id="file"
                as={DropFileInput}
                accept=".pdf"
              />
            ) : null}
            {isExplanationVisible ? (
              <>
                <Field
                  type="textarea"
                  id="explanation"
                  name="explanation"
                  label={"Wyjaśnienie"}
                  component={Textarea}
                  maxLength={1000}
                />
                <p
                  className={`analyze-info-modal__error ${
                    errors.explanation && touched.explanation
                      ? "analyze-info-modal__error--show"
                      : ""
                  }`}
                >
                  <SVG type={SVG_ENUM.ANNOTATION_ALERT} />
                  To pole jest obowiązkowe. Uzupełnij wyjaśnienie, aby móc
                  zapisać zmiany.
                </p>
              </>
            ) : null}
            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--wide"
                size="medium"
                styleType="strokeBlack"
              />
              <Button
                label={"Zapisz zmiany"}
                type="submit"
                onClick={() => null}
                className="button--wide "
                size="medium"
                styleType="primary"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AnalyzeForm;
