import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { PostType } from "types/PostTypes";

export default function useGetPost({ id }: { id: number }) {
  return useQuery({
    queryKey: ["posts", id],
    queryFn: async () => {
      return axiosInstance.get(`/posts/${id} `).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: PostType) => data,
    retry: false,
    staleTime: 1000 * 60 * 5,
  });
}
