import { VALIDATION_ENUM } from "enums";
import * as Yup from "yup";

const validation = () =>
  Yup.object().shape({
    companyName: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    nip: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    deadlineAt: Yup.string().required(VALIDATION_ENUM.REQUIRED),
  });

export default validation;
