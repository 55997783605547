import { useGetPostCategories } from "hooks/posts";
import { EmptyModal } from "shared";

interface CategoriesModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCategories: number[];
  handleCategoryClick: (id: number) => void;
  setSelectedCategories: (value: number[]) => void;
}

export default function CategoriesModal({
  isOpen,
  onClose,
  selectedCategories,
  handleCategoryClick,
  setSelectedCategories,
}: CategoriesModalProps) {
  const { data: categoryOptions } = useGetPostCategories();
  return (
    <EmptyModal
      title="Filtry"
      topLeftLabel="Wyczyść"
      topLeftOnClick={() => setSelectedCategories([])}
      isOpen={isOpen}
      onClose={onClose}
    >
      <h2 className="select__label">Kategoria</h2>
      <div className="posts-grid__categories">
        {categoryOptions?.map((category) => (
          <span
            className={`posts-grid__category ${
              selectedCategories.includes(category.value)
                ? "posts-grid__category--active"
                : ""
            }`}
            onClick={() => handleCategoryClick(category.value)}
          >
            {category.label}
          </span>
        ))}
      </div>
    </EmptyModal>
  );
}
