import { I18nDictionary } from "@editorjs/editorjs";

const TextEditorTranslation: I18nDictionary = {
  ui: {
    blockTunes: {
      toggler: {
        "Click to tune": "Kliknij, aby dostosować",
        "or drag to move": "lub przeciągnij, aby przenieść",
      },
    },
    inlineToolbar: {
      converter: {
        "Convert to": "Konwertuj na",
      },
    },
    toolbar: {
      toolbox: {
        Add: "Dodaj",
      },
    },
    popover: {
      "Convert to": "Konwertuj na",
    },
  },

  toolNames: {
    Text: "Akapit",
    Heading: "Nagłówek",
    List: "Lista",
    Warning: "Uwaga",
    Checklist: "Lista kontrolna",
    Quote: "Cytat",
    Code: "Kod",
    Delimiter: "Separator",
    "Raw HTML": "Fragment HTML",
    Table: "Tabela",
    Link: "Link",
    Marker: "Marker",
    Bold: "Pogrubiony",
    Italic: "Kursywa",
    InlineCode: "Kod liniowy",
    "Unordered List": "Lista nieuporządkowana",
    "Ordered List": "Lista uporządkowana",
  },

  tools: {
    warning: {
      Title: "Tytuł",
      Message: "Wiadomość",
    },
    link: {
      "Add a link": "Dodaj link",
    },

    stub: {
      "The block can not be displayed correctly.":
        "Blok nie może zostać poprawnie wyświetlony",
    },
    list: {
      title: "Lista",
      Ordered: "Uporządkowane",
      Unordered: "Nieuporządkowane",
      Checklist: "Lista kontrolna",
      "Start with": "Rozpocznij od",
      "Counter type": "Typ licznika",
      "Convert to": "Konwertuj na",
      "Add item": "Dodaj element",
    },
    paragraph: {
      "Type here...": "Wpisz tekst tutaj...",
    },
    header: {
      "Heading 1": "Nagłówek 1",
      "Heading 2": "Nagłówek 2",
      "Heading 3": "Nagłówek 3",
      "Heading 4": "Nagłówek 4",
      "Heading 5": "Nagłówek 5",
      "Heading 6": "Nagłówek 6",
    },
  },

  blockTunes: {
    delete: {
      Delete: "Usuń",
    },
    moveUp: {
      "Move up": "Przenieś w górę",
    },
    moveDown: {
      "Move down": "Przenieś w dół",
    },
  },
};

export default TextEditorTranslation;
