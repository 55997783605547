import moment from "moment";

export function formatDate(inputDate: string | Date): string {
  const now = moment();
  const date = moment(inputDate);

  const diffMinutes = now.diff(date, "minutes");
  const diffHours = now.diff(date, "hours");
    const diffDays = now.diff(date, "days");
    
  if (date.isAfter(now)) {
    return date.format("DD/MM/YYYY HH:mm");
  }
  if (diffMinutes < 60) {
    return `${diffMinutes}m temu`;
  } else if (diffHours < 24) {
    const minutes = diffMinutes % 60;
    return `${diffHours}h ${minutes > 0 ? `${minutes}m` : ""} temu`.trim();
  } else if (diffDays === 1) {
    return `wczoraj, ${date.format("HH:mm")}`;
  } else {
    return date.format("DD/MM/YYYY HH:mm");
  }
}
export default formatDate;
