import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { queryClient } from "App";
import axiosConfig from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { Toast } from "shared";

export const useDeletePost = (
  options?: UseMutationOptions<any, Error, number>,
  withoutToast?: boolean
) => {
  return useMutation({
    mutationKey: ["postdelete"],
    mutationFn: async (id: number) => {
      return axiosConfig.delete(`/posts/${id}`).then((res) => res.data);
    },
    onMutate: (variables) => {
      if (!withoutToast) {
        Toast({
          message: "Ładowanie...",
          type: TOAST_TYPES_ENUM.LOADING,
          id: "postdelete",
        });
      }
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      if (!withoutToast) {
        Toast({
          message: "Pomyślnie Usunięto informacje",
          type: TOAST_TYPES_ENUM.SUCCESS,
          id: "postdelete",
        });
      }
      queryClient.invalidateQueries({ queryKey: ["posts"] });

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },

    onError: (error, variables, context) => {
      if (!withoutToast) {
        Toast({
          message: "Błąd podczas usuwania informacji",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "postdelete",
        });
      }
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
