import { PostType } from "types/PostTypes";
import "./PostItem.scss";
import moment from "moment";
import { EditorJsOutput, PostStatusBadge, SVG } from "shared";
import { ROLES_ENUM, ROUTE_ENUM, SVG_ENUM } from "enums";
import { PostModalManage } from "screens/marketInfoPage/MarketInfoPage";
import { formatDate, isDesktop } from "utils";
import { useGetMe } from "hooks/auth";
import { useNavigate } from "react-router";

interface PostItemType {
  data: PostType;
  setModalManage: React.Dispatch<React.SetStateAction<PostModalManage>>;
}

const PostItem = ({ data, setModalManage }: PostItemType) => {
  const publishedAt = moment(data.publishedAt);
  const company = data.companies?.length ? data.companies[0] : null;
  const { data: authorizedUser } = useGetMe();
  const isUser = authorizedUser?.role === ROLES_ENUM.USER;
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    if (e.target.classList.contains("post-item__badge")) {
      e.stopPropagation();
    } else {
      navigate(ROUTE_ENUM.POST.replace(":id", data.id.toString()));
    }
  };

  return (
    <article
      className={`post-item ${isUser ? "post-item__user" : ""}`}
      key={data.uuid}
      onClick={(e) => isUser && handleClick(e)}
    >
      {!isDesktop() && !isUser ? (
        <div className="post-item__right">
          <PostStatusBadge status={data.status} />
          <div className="post-item__badges">
            <span
              className="post-item__badge post-item__badge--delete"
              onClick={() => setModalManage({ type: "deletePost", post: data })}
            >
              <SVG type={SVG_ENUM.TRASH} />
            </span>
            <span
              className="post-item__badge post-item__badge--edit"
              onClick={() => setModalManage({ type: "addPost", post: data })}
            >
              <SVG type={SVG_ENUM.EDIT} />
            </span>
          </div>
        </div>
      ) : null}
      <div className="post-item__image">
        {data?.file?.url ? (
          <img
            src={data.file?.url}
            alt={data.title}
            onError={(e: any) => {
              e.target.src = require("assets/images/PostPlaceholder.webp");
            }}
          />
        ) : (
          <img
            src={require("assets/images/PostPlaceholder.webp")}
            alt={data.title}
          />
        )}
      </div>
      <div className="post-item__content">
        {isUser ? (
          <>
            <p className="post-item__company-user">{company?.name}</p>
            <h2 className="post-item__title-user">{data?.title}</h2>
            <div className="post-item__link">
              Źródło:{" "}
              <a href={data.link} target="_blank" rel="noreferrer">
                <SVG type={SVG_ENUM.LINK} />
                {data.source}
              </a>
            </div>
            <p className="post-item__content-data">
              <EditorJsOutput outputData={JSON.parse(data?.content)} />
            </p>
            <p className="post-item__date">
              {data.publishedAt && formatDate(data.publishedAt)}
            </p>
          </>
        ) : (
          <>
            <div className="post-item__top">
              <span className="post-item__date">
                {publishedAt.format(`DD/MM/yyyy`)} godz.{" "}
                {publishedAt.format(`HH:mm`)}
              </span>
              {isDesktop() && !isUser ? (
                <div className="post-item__right">
                  <PostStatusBadge status={data.status} />
                  <div className="post-item__badges">
                    <span
                      className="post-item__badge post-item__badge--delete"
                      onClick={() =>
                        setModalManage({ type: "deletePost", post: data })
                      }
                    >
                      <SVG type={SVG_ENUM.TRASH} />
                    </span>
                    <span
                      className="post-item__badge post-item__badge--edit"
                      onClick={() =>
                        setModalManage({ type: "addPost", post: data })
                      }
                    >
                      <SVG type={SVG_ENUM.EDIT} />
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <p className="post-item__company">{company?.name}</p>
            <h2 className="post-item__title">{data?.title}</h2>
          </>
        )}
      </div>
    </article>
  );
};

export default PostItem;
