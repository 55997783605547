import React from "react";
import "./EditorJsOutput.scss";

type OutputData = {
  blocks: Array<{
    type: string;
    data: Record<string, any>;
  }>;
};

type EditorJsOutputProps = {
  outputData: OutputData;
};

type ListType = {
  content: string;
  index: number;
};

const EditorJsOutput: React.FC<EditorJsOutputProps> = ({ outputData }) => {
  const renderBlock = (block: { type: string; data: Record<string, any> }) => {
    switch (block.type) {
      case "paragraph":
        return <p>{block.data.text}</p>;
      case "header":
        return React.createElement(`h${block.data.level}`, {}, block.data.text);
      case "list":
        if (block.data.style === "ordered") {
          console.log(block.data.items);
          return (
            <ol>
              {block.data.items.map(({ content, index }: ListType) => (
                <li key={index}>{content}</li>
              ))}
            </ol>
          );
        }
        return (
          <ul>
            {block.data.items.map(({ content, index }: ListType) => (
              <li key={index}>{content}</li>
            ))}
          </ul>
        );
      case "image":
        return (
          <figure>
            <img src={block.data.file.url} alt={block.data.caption || ""} />
            {block.data.caption && (
              <figcaption>{block.data.caption}</figcaption>
            )}
          </figure>
        );
      case "quote":
        return (
          <blockquote>
            <p>{block.data.text}</p>
            <cite>{block.data.caption}</cite>
          </blockquote>
        );
      default:
        return null;
    }
  };

  return (
    <div className="texteditor__fonts">
      {outputData.blocks.map((block, index) => (
        <div className="editor-js-output" key={index}>{renderBlock(block)}</div>
      ))}
    </div>
  );
};

export default EditorJsOutput;
