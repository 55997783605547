enum ROUTE_ENUM {
  HOME = "/",
  REGISTER = "/register",
  LOGIN = "/login",
  LOGIN_ADMIN = "/login-admin",
  ENTER_CODE = "/enter-code",
  REGISTER_ADMIN = "/register-admin",
  LINK_EXPIRED = "/link-expired",
  RESET_PASSWORD = "/reset-password",
  CREATE_NEW_PASSWORD = "/create-new-password",
  DASHBOARD= "/dashboard",
  USERS = "/dashboard/users",
  COMPANIES = "/dashboard/companies",
  PAYMENTS = "/dashboard/payments",
  MARKET_INFORMATION = "/dashboard/market-information",
  ANALYSIS = "/dashboard/analyzes",
  PROFILE = "/dashboard/profile",
  SVG_TEST = "/svg-test",
  POST = "/dashboard/post/:id",
  
}

export default ROUTE_ENUM;
